<template>
  <v-dialog v-model="dialog" width="750" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-blur class="msaBlue white--text" v-bind="attrs" v-on="on">
        Add Note
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text"> Add Note </v-card-title>
      <v-card-text class="mt-4">
        <v-textarea
          v-model.trim="note"
          counter
          maxlength="1000"
          outlined
          :auto-grow="true"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" text color="red">cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          :disabled="disableSave"
          @click="confirm()"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddNote',
  computed: {
    disableSave() {
      return this.note.trim().length == 0;
    },
  },
  data() {
    return {
      dialog: false,
      note: '',
    };
  },
  methods: {
    close() {
      this.note = '';
      this.dialog = false;
    },
    confirm() {
      this.$emit('addNote', this.note);
      this.close();
    },
  },
};
</script>

<style></style>
