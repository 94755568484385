<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="getAudits()"
          >
            <v-icon> mdi-file-find </v-icon>
          </v-btn>
        </template>
        <span>Audit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col class="grow"> Audits </v-col>
          <v-col class="shrink">
            <v-btn icon color="white" @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :key="tableKey"
        :headers="headers"
        :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
        :items="items"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAt | filterAsLocalDate }}
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="msaBlue white--text" @click="newAuditDialog = true">
          Audit Form
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="newAuditDialog" width="600" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text"> Comment </v-card-title>
        <v-card-text class="mt-4">
          <v-textarea
            v-model="comment"
            counter
            maxlength="1000"
            outlined
            hide-details
            placeholder="Optional"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="red--text"
            @click="
              newAuditDialog = false;
              comment = '';
            "
          >
            cancel
          </v-btn>
          <v-btn class="msaBlue white--text" @click="auditForm()">
            confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormAudits',
  props: {
    formInstanceId: {
      type: Number,
    },
  },
  data() {
    return {
      tableKey: 0,
      newAuditDialog: false,
      comment: '',
      dialog: false,
      items: [],
      originalOptions: {},
      headers: [
        {
          text: 'Auditor',
          value: 'name',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Notes',
          value: 'description',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '220px',
        },
        {
          text: 'Created Date',
          value: 'createdAt',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '150px',
        },
      ],
    };
  },
  methods: {
    onClose() {
      this.tableKey++;
      this.dialog = false;
    },
    getAudits() {
      const params = {
        loaderText: 'loading...',
        id: this.formInstanceId,
      };
      const url = 'get-form-instance-audits?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
        })
        .catch((error) => error);
    },
    auditForm() {
      const params = {
        loaderText: 'loading...',
        id: this.formInstanceId,
        comment: this.comment,
      };
      const url = 'audit-form-instance?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.newAuditDialog = false;
          this.getAudits();
          this.$emit('audited');
          this.comment = '';
        })
        .catch((error) => error);
    },
  },
};
</script>

<style></style>
