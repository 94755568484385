<template>
  <v-container fluid ref="container">
    <v-row dense>
      <v-col cols="auto" class="font-weight-bold black--text mr-auto">
        {{ element.name }}
      </v-col>
      <v-col
        v-if="element.description.length > 0"
        cols="12"
        class="black--text"
      >
        {{ element.description }}
      </v-col>
      <v-col cols="12">
        <v-img
          :style="{ cursor: 'pointer' }"
          :src="imageSrc()"
          contain
          :max-width="maxWidth"
          :max-height="maxHeight"
          @click="previewImage"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ImageQuestion',
  props: {
    element: {
      type: Object,
    },
  },
  data() {
    return {
      maxWidth: null,
      maxHeight: null,
    };
  },
  computed: {
    isPreview() {
      return (
        this.$route.name == 'FormTemplatePreview' ||
        this.$route.name == 'PublicFormTemplatePreview'
      );
    },
  },
  methods: {
    imageSrc() {
      if (this.isPreview) {
        return (
          process.env.VUE_APP_BASE_URL +
          'download-form-element-file-for-form-template-preview?jwtToken=' +
          this.$store.getters.jwtToken +
          '&id=' +
          this.element.questionFile.id
        );
      }
      return (
        process.env.VUE_APP_BASE_URL +
        'download-form-element-file?jwtToken=' +
        this.$store.getters.jwtToken +
        '&id=' +
        this.element.questionFile.id +
        '&formInstanceId=' +
        this.element.formInstanceId +
        '&formInstanceElementId=' +
        this.element.id
      );
    },
    calculateImageMaxSize() {
      const padding = 40;
      this.maxWidth = this.$refs.container.clientWidth - padding;
      this.maxHeight = window.innerHeight / 2;
    },
    previewImage() {
      window.open(this.imageSrc(), '_blank');
    },
  },
  mounted() {
    this.calculateImageMaxSize();
  },
  created() {
    window.addEventListener('resize', this.calculateImageMaxSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateImageMaxSize);
  },
};
</script>
