<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" class="font-weight-bold black--text">
        {{ element.name }}
      </v-col>
      <v-col
        v-if="element.description.length > 0"
        cols="12"
        class="black--text"
      >
        {{ element.description }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'InstructionsElement',
  props: {
    element: {
      type: Object,
    },
  },
};
</script>

<style></style>
