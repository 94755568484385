<template>
  <v-dialog
    v-model="dialog"
    width="750"
    persistent
    v-if="correctiveAction != null"
  >
    <v-card>
      <v-footer
        padless
        tile
        style="position: sticky; top: 0; z-index: 999"
        class="msaBlue white--text"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="grow text-h6 pl-6"> Corrective Action Details </v-col>
            <v-col align="right" class="shrink pl-0 pr-6">
              <v-btn
                icon
                color="white"
                @click="
                  dialog = false;
                  $emit('cancel');
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>

      <v-card-text class="mt-4">
        <v-form ref="form">
          <v-row align="center">
            <v-col cols="12" align="center" v-if="isMobile">
              <v-btn-toggle
                v-model="edited.statusId"
                mandatory
                :key="reload"
                @change="reload = !reload"
              >
                <v-btn
                  :value="1"
                  width="100"
                  :class="edited.statusId == 1 ? 'msaBlue' : ''"
                  @click="$refs.resolvedBy.show()"
                  :disabled="!isCaEditable"
                >
                  <v-icon :color="edited.statusId == 1 ? 'white' : ''">
                    mdi-check
                  </v-icon>
                </v-btn>
                <v-btn
                  :value="2"
                  width="100"
                  :class="edited.statusId == 2 ? 'red' : ''"
                  @click="unresolveCa()"
                  :disabled="!isCaEditable"
                >
                  <v-icon :color="edited.statusId == 2 ? 'white' : ''">
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                :value="edited.statusId == 1 ? 'Resolved' : 'Unresolved'"
                dense
                outlined
                hide-details
                readonly
                label="Status"
                :disabled="!isCaEditable"
              ></v-text-field>
            </v-col>
            <v-col cols="4" align="right" v-if="!isMobile">
              <v-btn-toggle
                v-model="edited.statusId"
                mandatory
                :key="reload"
                @change="reload = !reload"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :value="1"
                      width="100"
                      :class="edited.statusId == 1 ? 'msaBlue' : ''"
                      @click="
                        edited.statusId == 2 ? $refs.resolvedBy.show() : null
                      "
                      :disabled="!isCaEditable"
                      v-on="on"
                    >
                      <v-icon :color="edited.statusId == 1 ? 'white' : ''">
                        mdi-check
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Resolve</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :value="2"
                      width="100"
                      :class="edited.statusId == 2 ? 'red' : ''"
                      @click="unresolveCa()"
                      :disabled="!isCaEditable"
                      v-on="on"
                    >
                      <v-icon :color="edited.statusId == 2 ? 'white' : ''">
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Unresolve</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-text-field
                v-model="edited.hazardText"
                dense
                counter
                maxlength="2000"
                outlined
                label="Hazard"
                :rules="required"
                :disabled="!isCaEditable"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                v-model="edited.controlText"
                dense
                counter
                maxlength="2000"
                outlined
                label="Control"
                :rules="required"
                :disabled="!isCaEditable"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="edited.assignedStudentUserId"
                :items="users"
                item-text="fullname"
                item-value="id"
                outlined
                dense
                chips
                :rules="required"
                small-chips
                hide-details
                label="Assigned To"
                :readonly="!isCaEditable"
                @change="updateAssignedToName($event)"
              >
                <template v-slot:item="{ item }">
                  <v-row no-gutters class="my-1">
                    <v-col cols="12" class="font-weight-medium">
                      {{ item.fullname }}
                    </v-col>
                    <v-col cols="12" class="text-caption">
                      {{ userTitle(item) }}
                    </v-col>
                  </v-row>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-select
                v-model="edited.hazardRankSeverity"
                :items="severities"
                outlined
                dense
                label="Severity"
                :clearable="isCaEditable"
                :readonly="!isCaEditable"
                item-text="text"
                item-value="code"
                chips
                :rules="
                  !edited.hazardRankProbability && !edited.hazardRankSeverity
                    ? []
                    : required
                "
                @change="$refs.form.validate()"
              ></v-select>
            </v-col>
            <v-col class="pb-0">
              <v-select
                v-model="edited.hazardRankProbability"
                :items="probabilities"
                outlined
                dense
                label="Probability"
                :clearable="isCaEditable"
                :readonly="!isCaEditable"
                item-text="text"
                item-value="code"
                chips
                :rules="
                  !edited.hazardRankProbability && !edited.hazardRankSeverity
                    ? []
                    : required
                "
                @change="$refs.form.validate()"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-select
                v-model="edited.frequencyCode"
                :items="frequencies"
                item-text="description"
                item-value="code"
                outlined
                hide-details
                :clearable="isCaEditable"
                dense
                label="Frequency"
                :readonly="!isCaEditable"
                chips
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="msaGrey text-body-1 py-2 mt-4">
                <v-badge inline :value="imageCount > 0" :content="imageCount">
                  Images
                </v-badge>
              </v-card-title>
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(image, index) in correctiveAction.images"
                            :key="index"
                          >
                            <td align="center">
                              <v-row no-gutters align="center">
                                <v-col
                                  @click="previewImage(imageSrc(image))"
                                  :style="{ cursor: 'pointer' }"
                                >
                                  <v-img
                                    :src="imageSrc(image)"
                                    contain
                                    :max-width="isMobile ? '180px' : '300px'"
                                    max-height="150px"
                                  >
                                  </v-img>
                                </v-col>
                                <v-col
                                  class="shrink"
                                  v-if="isUnsaved(image.id)"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-blur
                                        text
                                        small
                                        v-on="on"
                                        color="red"
                                        @click="$emit('deleteImage', index)"
                                      >
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Delete</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col align="right" v-if="isCaEditable">
                    <AddImage @addImage="$emit('addImage', $event)" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="msaGrey text-body-1 py-2">
                <v-badge inline :value="noteCount > 0" :content="noteCount">
                  Notes
                </v-badge>
              </v-card-title>
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(note, index) in correctiveAction.notes"
                            :key="note.id"
                          >
                            <td>
                              <v-row no-gutters align="center">
                                <v-col class="grow">
                                  <v-row no-gutters>
                                    <v-col cols="12">
                                      {{ note.description }}
                                    </v-col>
                                    <v-col class="text-italic text-caption">
                                      By {{ note.createdByName }} on
                                      {{
                                        note.createdOnMobileAt
                                          | filterAsLocalDateTime
                                      }}
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col class="shrink" v-if="isUnsaved(note.id)">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-blur
                                        text
                                        small
                                        v-on="on"
                                        color="red"
                                        @click="$emit('deleteNote', index)"
                                      >
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Delete</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col align="right" v-if="isCaEditable">
                    <AddNote @addNote="$emit('addNote', $event)" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col class="grow" align="right">
              <v-btn
                class="red--text"
                @click="
                  dialog = false;
                  $emit('cancel');
                "
              >
                cancel
              </v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn
                class="green white--text"
                @click="saveDetails()"
                :disabled="disableSave"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
    <AddResolvedBy
      ref="resolvedBy"
      :users="users"
      @cancelled="edited.statusId = correctiveAction.statusId"
      @resolved="addResolvedBy($event)"
    />
  </v-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import AddImage from '@/components/FormInstances/AddImage.vue';
import AddNote from '@/components/FormInstances/AddNote.vue';
import AddResolvedBy from '@/components/FormInstances/AddResolvedBy.vue';
export default {
  name: 'CorrectiveActionDetails',
  components: {
    AddImage,
    AddNote,
    AddResolvedBy,
  },
  props: {
    correctiveAction: {
      type: Object,
    },
    users: {
      type: Array,
    },
    isCaEditable: {
      type: Boolean,
    },
    severities: {
      type: Array,
    },
    probabilities: {
      type: Array,
    },
    frequencies: {
      type: Array,
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.init();
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
  },
  computed: {
    isUnsaved() {
      return (id) => !Number.isInteger(id);
    },
    imageSrc() {
      return (image) => {
        if (this.isUnsaved(image.id)) {
          return image.src;
        } else {
          return this.getImage(image.id);
        }
      };
    },
    disableSave() {
      if (
        this.edited.controlText.trim() === '' ||
        this.edited.hazardText.trim() === ''
      ) {
        return true;
      }
      if (
        this.edited.hazardRankSeverity &&
        !this.edited.hazardRankProbability
      ) {
        return true;
      }
      if (
        this.edited.hazardRankProbability &&
        !this.edited.hazardRankSeverity
      ) {
        return true;
      }
      if (!this.edited.assignedStudentUserId) {
        return true;
      }
      return (
        JSON.stringify(this.edited) === JSON.stringify(this.correctiveAction)
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    userTitle() {
      return (user) => {
        if (user.isSupervisor && user.isManager) {
          return 'Supervisor & Manager';
        }

        if (user.isSupervisor) {
          return 'Supervisor';
        }

        if (user.isManager) {
          return 'Manager';
        }

        return 'User';
      };
    },
    imageCount() {
      return this.correctiveAction.images.length;
    },
    noteCount() {
      return this.correctiveAction.notes.length;
    },
  },
  data() {
    return {
      dialog: false,
      edited: {
        hazardRankSeverity: '',
        hazardRankProbability: '',
        statusId: '',
        hazardText: '',
        controlText: '',
        assignedStudentUserId: '',
        assignedToName: '',
        frequencyCode: '',
        lastResolvedAt: '',
        lastResolvedByName: '',
        lastResolvedBy: '',
      },
      reload: false,
      required: [(v) => !!v || 'This field is required'],
    };
  },
  methods: {
    previewImage(imageSrc) {
      const image = new Image();
      image.src = imageSrc;
      const newWindow = window.open('');
      newWindow.document.write(image.outerHTML);
    },
    show() {
      this.dialog = true;
    },
    getImage(id) {
      return `${
        process.env.VUE_APP_BASE_URL
      }download-corrective-action-image?format=image&jwtToken=${
        this.$store.getters.jwtToken
      }&id=${id}&t=${new Date()}`;
    },
    updateAssignedToName(event) {
      if (!event) {
        this.edited.assignedToName = '';
        return;
      }

      const user = this.users.find((i) => i.id == event);
      this.edited.assignedToName = user.fullname;
    },
    saveDetails() {
      this.edited.hazardRankSeverity = this.edited.hazardRankSeverity ?? '';
      this.edited.hazardRankProbability =
        this.edited.hazardRankProbability ?? '';
      this.edited.frequencyCode = this.edited.frequencyCode ?? '';
      this.$emit('saveCa', this.edited);
      this.dialog = false;
    },
    init() {
      this.edited = JSON.parse(JSON.stringify(this.correctiveAction));
    },
    unresolveCa() {
      this.edited.lastResolvedAt = '';
      this.edited.lastResolvedByName = '';
      this.edited.lastResolvedBy = '';
      this.edited.statusId = 2;
    },
    addResolvedBy(payload) {
      this.edited.lastResolvedAt = DateTime.now().toFormat('yyyy-LL-dd HH:mm');
      this.edited.lastResolvedBy = payload.id;
      this.edited.lastResolvedByName = payload.fullname;
      this.edited.statusId = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
