<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="auto" class="font-weight-bold black--text mr-auto">
        {{ element.name }}
      </v-col>
      <v-col v-if="element.description.length" cols="12" class="black--text">
        {{ element.description }}
      </v-col>
      <v-col cols="12">
        <v-chip :style="{ cursor: 'pointer' }" @click="previewFile">
          <v-icon color="red"> mdi-file-pdf-box </v-icon>
          Click to view
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PdfQuestion',
  props: {
    element: {
      type: Object,
    },
  },
  computed: {
    isPreview() {
      return (
        this.$route.name == 'FormTemplatePreview' ||
        this.$route.name == 'PublicFormTemplatePreview'
      );
    },
  },
  methods: {
    url() {
      if (this.isPreview) {
        return (
          process.env.VUE_APP_BASE_URL +
          'download-form-element-file-for-form-template-preview?jwtToken=' +
          this.$store.getters.jwtToken +
          '&id=' +
          this.element.questionFile.id
        );
      }
      return (
        process.env.VUE_APP_BASE_URL +
        'download-form-element-file?jwtToken=' +
        this.$store.getters.jwtToken +
        '&id=' +
        this.element.questionFile.id +
        '&formInstanceId=' +
        this.element.formInstanceId +
        '&formInstanceElementId=' +
        this.element.id
      );
    },
    previewFile() {
      const link = document.createElement('a');
      link.href = this.url();
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();

      if (
        this.element.statusId !==
        this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY
      ) {
        this.$emit('updateElement', {
          key: 'statusId',
          value: this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY,
        });
      }
    },
  },
};
</script>
