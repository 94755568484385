<template>
  <v-row dense>
    <v-col>
      <v-autocomplete
        item-disabled="disabled"
        :value="value"
        outlined
        dense
        :multiple="multiple"
        :placeholder="label"
        :label="label"
        :hide-details="hideDetails"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :clearable="clearable"
        chips
        :return-object="returnObject"
        :deletable-chips="deletableChips"
        :disabled="disabled"
        :hint="hint"
        :rules="rules"
        :persistent-hint="persistentHint"
        :prepend-icon="prependIcon"
        :error="error"
        :loading="loading"
        @change="$emit('change', $event)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
      >
        <template v-slot:[`selection`]="{ item }">
          <v-chip
            :color="item.disabled ? 'red lighten-1' : 'msaBlue'"
            dark
            :close="deletableChips"
            small
          >
            <div class="overflow-x-hidden text-truncate">
              {{ isObject(item) ? item[itemText] : item }}
              {{ item.disabled ? '(archived)' : '' }}
            </div>
          </v-chip>
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            :style="{ display: item.disabled ? 'none' : '' }"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center">
                  {{ item.name }}
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormProjectAutoComplete',
  props: {
    value: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    prependIcon: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isObject() {
      return (item) => typeof item === 'object';
    },
  },
};
</script>

<style></style>
