<template>
  <v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Signature Location </v-col>
          <v-col class="shrink">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <GmapMap
        :center="{ lat: latitude, lng: longitude }"
        :zoom="16"
        :style="{ width: '100%', height: '600px' }"
      >
        <GmapMarker
          :position="{ lat: latitude, lng: longitude }"
          @click="center = { lat: latitude, lng: longitude }"
        />
      </GmapMap>
    </v-card>
  </v-dialog>
</template>

<script>
import { helpers } from 'gmap-vue';
const { googleMapsApiInitializer } = helpers;
export default {
  name: 'SignatureMap',
  props: {
    latitude: {
      default: '',
    },
    longitude: {
      default: '',
    },
  },
  watch: {
    dialog(val) {
      if (val && this.latitude != '' && this.longitude != '') {
        setTimeout(() => {
          this.init();
        }, 100);
      }
    },
  },
  data() {
    return {
      map: null,
      dialog: false,
    };
  },
  methods: {
    init() {
      googleMapsApiInitializer(
        {
          key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
        },
        false,
      );
    },
    show() {
      this.dialog = true;
    },
  },
};
</script>

<style></style>
