<template>
  <FloatingNavigation
    :items="sectionNavigationItems"
    :width="sectionNavWidth"
    item-text="text"
    item-value="value"
    @click="$emit('goToSection', $event.value)"
    title="Sections"
  />
</template>

<script>
import FloatingNavigation from '@/components/FloatingNavigation.vue';
export default {
  name: 'SectionNavigation',
  components: {
    FloatingNavigation,
  },
  props: ['availableWidth', 'sections'],
  computed: {
    sectionNavigationItems() {
      const result = [{ text: 'Summary', value: -1 }];
      this.sections.forEach((section, index) => {
        result.push({ text: section.name, value: index });
      });
      result.push({ text: 'Signatures', value: -2 });
      return result;
    },
  },
  watch: {
    availableWidth() {
      this.setupSectionNavWidth();
    },
  },
  data() {
    return {
      sectionNavWidth: 0,
    };
  },
  methods: {
    setupSectionNavWidth() {
      this.sectionNavWidth = this.availableWidth;
    },
  },
  mounted() {
    this.setupSectionNavWidth();
  },
};
</script>

<style></style>
