<template>
  <v-card
    ref="card"
    :style="{
      position: 'sticky',
      top: floatingTopMargin + 'px',
      left: 0,
    }"
    :width="width"
    v-if="lapTop"
  >
    <v-card-title class="lightGrey" v-if="title.length > 0">
      {{ title }}
    </v-card-title>
    <v-card-text class="pa-0">
      <v-virtual-scroll
        :items="items"
        :height="height"
        :item-height="itemHeight"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item
            :key="index"
            @click="
              $emit('click', {
                value: itemValue == 'index' ? index : item[itemValue],
                ...item,
              })
            "
          >
            <v-list-item-content class="msaBlue--text">
              <v-list-item-title>
                {{ item[itemText] }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index != items.length - 1"> </v-divider>
        </template>
      </v-virtual-scroll>
    </v-card-text>
  </v-card>

  <v-menu
    v-else-if="tablet"
    bottom
    transition="scale-transition"
    offset-y
    v-model="menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="msaBlue white--text"
        v-bind="attrs"
        v-on="on"
        :style="{
          'z-index': 99,
          position: 'sticky',
          top: floatingTopMargin + 'px',
          'margin-top': '-65px',
        }"
      >
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
    </template>

    <v-list class="py-0">
      <v-list-item class="lightGrey" v-if="title.length > 0">
        <v-list-item-content>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="title.length > 0"></v-divider>
      <v-virtual-scroll
        :items="items"
        :height="height"
        :item-height="itemHeight"
        :width="mobileWidth"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item
            :key="index"
            @click="
              $emit('click', {
                value: itemValue == 'index' ? index : item[itemValue],
                ...item,
              })
            "
          >
            <v-list-item-content class="msaBlue--text">
              <v-list-item-title>{{ item[itemText] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index != items.length - 1"> </v-divider>
        </template>
      </v-virtual-scroll>
    </v-list>
  </v-menu>

  <v-menu v-else-if="phone" bottom transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="msaBlue white--text"
        v-bind="attrs"
        v-on="on"
        :style="{
          'z-index': 99,
          position: 'sticky',
          top: floatingTopMargin + 'px',
          'margin-top': '-65px',
        }"
        v-blur
        @click="dialog = true"
      >
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
    </template>

    <v-dialog v-model="dialog" fullscreen>
      <v-card>
        <v-card-title class="lightGrey" v-if="title.length > 0">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-virtual-scroll
            :items="items"
            :height="height"
            :item-height="itemHeight"
          >
            <template v-slot:default="{ item, index }">
              <v-list-item
                :key="index"
                @click="
                  $emit('click', {
                    value: itemValue == 'index' ? index : item[itemValue],
                    ...item,
                  });
                  dialog = false;
                "
              >
                <v-list-item-content class="msaBlue--text">
                  <v-list-item-title>{{ item[itemText] }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index != items.length - 1"> </v-divider>
            </template>
          </v-virtual-scroll>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-menu>
</template>

<script>
export default {
  name: 'FloatingNavigation',
  props: {
    width: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    itemHeight: {
      type: Number,
      default: 50,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'index',
    },
  },
  data() {
    return {
      height: 0,
      buttonHeight: 36,
      drawer: false,
      mobileWidth: 0,
      dialog: false,
      floatingTopMargin: 48,
      menu: false,
    };
  },
  computed: {
    itemsTotalHeight() {
      return this.items.length * this.itemHeight;
    },
    lapTop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    tablet() {
      return (
        (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm) &&
        this.$vuetify.breakpoint.height > this.$vuetify.breakpoint.thresholds.xs
      );
    },
    phone() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.height <=
          this.$vuetify.breakpoint.thresholds.xs
      );
    },
  },
  methods: {
    setupHeight() {
      const top =
        this.buttonHeight +
        document.getElementById('cht-menu-nav').offsetHeight;
      const bottom = 160;
      const availableHeight = window.innerHeight - top - bottom;
      const mobileHeader = this.title.length > 0 ? 94 : 0;
      this.height = this.phone
        ? window.innerHeight - mobileHeader
        : availableHeight > this.itemsTotalHeight
        ? this.itemsTotalHeight
        : availableHeight;
    },
    setupMobileWidth() {
      if (this.phone) {
        this.mobileWidth = window.innerWidth;
      } else {
        this.mobileWidth = window.innerWidth / 4;
      }
    },
    closeMenu() {
      this.menu = false;
    },
  },
  mounted() {
    this.setupHeight();
    this.setupMobileWidth();
  },
  created() {
    window.addEventListener('resize', this.setupHeight);
    window.addEventListener('resize', this.setupMobileWidth);
    window.addEventListener('scroll', this.closeMenu);
  },
  destroyed() {
    window.removeEventListener('resize', this.setupHeight);
    window.removeEventListener('resize', this.setupMobileWidth);
    window.removeEventListener('scroll', this.closeMenu);
  },
};
</script>

<style></style>
