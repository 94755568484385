<template>
  <span>
    <v-btn class="msaBlue white--text" @click="$refs.uploader.click()">
      Add File
    </v-btn>
    <input
      type="file"
      ref="uploader"
      class="d-none"
      accept=".pdf, .doc, .docx, .xls, .xlsx"
      multiple
      @change="onFileUpload"
    />
  </span>
</template>

<script>
export default {
  props: ['formInstanceElementId'],
  name: 'AddFile',
  methods: {
    async createGuid() {
      const url = 'create-guid?format=json';
      return this.$axios
        .post(url, { loaderText: 'Loading...' })
        .then((response) => response.data);
    },
    async onFileUpload(event) {
      const files = event.target.files;
      if (files.length === 0) {
        return;
      }

      const badEntries = [];
      const goodEntries = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let maxFileSize = this.$constants.MAX_FILE_SIZE['10MB'];
        if (file.type != 'application/pdf') {
          maxFileSize = this.$constants.MAX_FILE_SIZE['100KB'];
        }
        try {
          await this.$helpers.processFile(
            file,
            this.$constants.ACCEPTED_MIME_TYPES.DOCUMENT,
            true,
            maxFileSize,
          );
        } catch (error) {
          if (error.message) {
            badEntries.push(file.name + ' (' + error.message + ')');
            continue;
          }
        }

        const filenameInfo = this.$helpers.splitFilenameByExtension(file.name);
        const nameWithoutExtension = filenameInfo.name;
        const guid = await this.createGuid();
        goodEntries.push({
          name: nameWithoutExtension,
          filename: file.name,
          mimeType: file.type,
          guid: guid,
          id: guid,
          formInstanceElementId: this.formInstanceElementId,
          createdAt: new Date().toISOString(),
          binary: file,
        });
      }
      if (badEntries.length) {
        this.$root.showMessage(
          'Warning',
          'The following files are not supported: <br>' +
            badEntries.join('<br>'),
          () => true,
          null,
          'OK',
        );
      }
      if (goodEntries.length) {
        this.$emit('onFileUpload', goodEntries);
      }
    },
  },
};
</script>
