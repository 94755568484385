var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.lapTop)?_c('v-card',{ref:"card",style:({
    position: 'sticky',
    top: _vm.floatingTopMargin + 'px',
    left: 0,
  }),attrs:{"width":_vm.width}},[(_vm.title.length > 0)?_c('v-card-title',{staticClass:"lightGrey"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"pa-0"},[_c('v-virtual-scroll',{attrs:{"items":_vm.items,"height":_vm.height,"item-height":_vm.itemHeight},scopedSlots:_vm._u([{key:"default",fn:function({ item, index }){return [_c('v-list-item',{key:index,on:{"click":function($event){return _vm.$emit('click', {
              value: _vm.itemValue == 'index' ? index : item[_vm.itemValue],
              ...item,
            })}}},[_c('v-list-item-content',{staticClass:"msaBlue--text"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])],1)],1),(index != _vm.items.length - 1)?_c('v-divider'):_vm._e()]}}],null,false,2871142607)})],1)],1):(_vm.tablet)?_c('v-menu',{attrs:{"bottom":"","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"msaBlue white--text",style:({
        'z-index': 99,
        position: 'sticky',
        top: _vm.floatingTopMargin + 'px',
        'margin-top': '-65px',
      })},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticClass:"py-0"},[(_vm.title.length > 0)?_c('v-list-item',{staticClass:"lightGrey"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.title))])],1)],1):_vm._e(),(_vm.title.length > 0)?_c('v-divider'):_vm._e(),_c('v-virtual-scroll',{attrs:{"items":_vm.items,"height":_vm.height,"item-height":_vm.itemHeight,"width":_vm.mobileWidth},scopedSlots:_vm._u([{key:"default",fn:function({ item, index }){return [_c('v-list-item',{key:index,on:{"click":function($event){return _vm.$emit('click', {
              value: _vm.itemValue == 'index' ? index : item[_vm.itemValue],
              ...item,
            })}}},[_c('v-list-item-content',{staticClass:"msaBlue--text"},[_c('v-list-item-title',[_vm._v(_vm._s(item[_vm.itemText]))])],1)],1),(index != _vm.items.length - 1)?_c('v-divider'):_vm._e()]}}])})],1)],1):(_vm.phone)?_c('v-menu',{attrs:{"bottom":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"blur",rawName:"v-blur"}],staticClass:"msaBlue white--text",style:({
        'z-index': 99,
        position: 'sticky',
        top: _vm.floatingTopMargin + 'px',
        'margin-top': '-65px',
      }),on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]}}])},[_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.title.length > 0)?_c('v-card-title',{staticClass:"lightGrey"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card-text',{staticClass:"pa-0"},[_c('v-virtual-scroll',{attrs:{"items":_vm.items,"height":_vm.height,"item-height":_vm.itemHeight},scopedSlots:_vm._u([{key:"default",fn:function({ item, index }){return [_c('v-list-item',{key:index,on:{"click":function($event){_vm.$emit('click', {
                  value: _vm.itemValue == 'index' ? index : item[_vm.itemValue],
                  ...item,
                });
                _vm.dialog = false;}}},[_c('v-list-item-content',{staticClass:"msaBlue--text"},[_c('v-list-item-title',[_vm._v(_vm._s(item[_vm.itemText]))])],1)],1),(index != _vm.items.length - 1)?_c('v-divider'):_vm._e()]}}])})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }