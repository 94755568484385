<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" class="font-weight-bold black--text mr-auto">
        {{ element.name }}
      </v-col>
      <v-col
        v-if="element.description.length > 0"
        cols="12"
        class="black--text"
      >
        {{ element.description }}
      </v-col>
      <v-col cols="12">
        <v-dialog
          ref="dialog"
          v-model="pickerDialog"
          :return-value.sync="dateTimeInput"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :placeholder="placeholder"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              clearable
              :value="formattedDateTimeInput"
              @click:clear="clearInput"
              hide-details
              :disabled="disableQuestion"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTimeInput"
            scrollable
            v-if="isDatePicker"
            :min="$constants.DATE_SELECTOR_RANGE.MIN"
            :max="$constants.DATE_SELECTOR_RANGE.MAX"
          >
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="pickerDialog = false">
              Cancel
            </v-btn>
            <v-btn class="msaBlue white--text" @click="confirmPick"> OK </v-btn>
          </v-date-picker>
          <v-time-picker
            v-model="dateTimeInput"
            scrollable
            v-else
            format="24hr"
            :key="timePickerReloadKey"
          >
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="pickerDialog = false">
              Cancel
            </v-btn>
            <v-btn class="msaBlue white--text" @click="confirmPick"> OK </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
      <v-col>
        <v-switch
          v-model="statusId"
          :true-value="$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE"
          :false-value="statusIdFalseValue"
          label="Not applicable"
          dense
          hide-details
          class="ma-0 pa-0"
          color="msaBlue"
          :disabled="!isEditable"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';

// This is the format for datetime coming from the backend
const TARGET_MASK = 'MMMM, dd yyyy HH:mm:ss ZZZ';
export default {
  name: 'DateTimePickerQuestion',
  props: {
    element: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
    },
  },
  computed: {
    dateTimeInput: {
      // getter makes sure the value is formatted so that picker can read it
      get() {
        if (!this.element.dateTimeInput) {
          return '';
        }
        if (this.isDatePicker) {
          const date = DateTime.fromFormat(
            this.element.dateTimeInput,
            TARGET_MASK,
          );
          return date.toLocal().toISODate();
        }
        const time = DateTime.fromFormat(
          this.element.dateTimeInput,
          TARGET_MASK,
        );
        return time.toLocal().toFormat('HH:mm');
      },
      set(value) {
        const updatedValue = this.getValueForUpdate(value);
        this.$emit('updateElement', {
          key: 'dateTimeInput',
          value: updatedValue,
        });

        const statusId =
          updatedValue == ''
            ? this.$constants.FORM_ELEMENT_STATUS.UNSATISFACTORY
            : this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY;
        this.$emit('updateElement', {
          key: 'statusId',
          value: statusId,
        });
      },
    },
    placeholder() {
      return this.isDatePicker ? 'Date' : 'Time';
    },
    isDatePicker() {
      return this.element.typeId == this.$constants.FORM_ELEMENT_TYPES.DATE;
    },
    statusIdFalseValue() {
      return this.dateTimeInput == ''
        ? this.$constants.FORM_ELEMENT_STATUS.UNSATISFACTORY
        : this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY;
    },
    statusId: {
      get() {
        return this.element.statusId;
      },
      set(value) {
        this.$emit('updateElement', {
          key: 'statusId',
          value,
        });
      },
    },
    disableQuestion() {
      return (
        this.statusId == this.$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE ||
        !this.isEditable
      );
    },
  },
  data() {
    return {
      required: [(v) => !!v || 'This field is required'],
      pickerDialog: false,
      formattedDateTimeInput: '',
      // just a work around to force the time picker to switch to the hour picker after user hits ok
      timePickerReloadKey: 1,
    };
  },
  methods: {
    confirmPick() {
      this.$refs.dialog.save(this.dateTimeInput);
      this.updateFormattedDateTimeInput();
    },
    updateFormattedDateTimeInput() {
      if (!this.dateTimeInput) {
        this.formattedDateTimeInput = '';
        if (!this.isDatePicker) {
          this.timePickerReloadKey++;
        }

        return;
      }
      if (this.isDatePicker) {
        this.formattedDateTimeInput = DateTime.fromFormat(
          this.dateTimeInput,
          'yyyy-MM-dd',
        ).toFormat('MMM dd, yyyy');
        return;
      }
      const time = DateTime.fromFormat(this.dateTimeInput, 'HH:mm');
      this.timePickerReloadKey++;
      this.formattedDateTimeInput = time.toFormat('hh:mm a');
    },
    clearInput() {
      this.dateTimeInput = '';
      this.updateFormattedDateTimeInput();
    },
    // create datetime in the format which is the same as the format coming from the backend
    getValueForUpdate(raw) {
      if (!raw) {
        return '';
      }
      let dateTimeString = '';
      if (this.isDatePicker) {
        // for date picker, we add the first hour of the day as the time so that Lucee will read it properly
        dateTimeString = `${raw} 01:00:00`;
      } else {
        // for time picker, we add the current date as the date so that when other user reads it from a different timezone, it will be formatted properly
        dateTimeString = `${DateTime.now().toISODate()} ${raw}:00`;
      }
      return DateTime.fromFormat(dateTimeString, 'yyyy-MM-dd HH:mm:ss')
        .toUTC()
        .toFormat(TARGET_MASK);
    },
  },
  mounted() {
    this.updateFormattedDateTimeInput();
  },
};
</script>

<style></style>
