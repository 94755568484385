<template>
  <v-dialog v-model="dialog" width="750" persistent>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Resolve Corrective Action
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12">
            <CustomizedAutoComplete
              :value="resolvedBy"
              label="Resolved By"
              :items="users"
              item-text="fullname"
              hide-details
              return-object
              @change="resolvedBy = $event"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="cancel()">cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          @click="confirm()"
          :disabled="disabled"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddResolvedBy',
  props: {
    users: {
      type: Array,
    },
  },
  computed: {
    disabled() {
      return this.resolvedBy == null;
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: 'Name',
          value: 'fullname',
          align: 'start',
          class: 'lightGrey',
        },
      ],
      resolvedBy: null,
    };
  },
  methods: {
    show() {
      this.dialog = true;
      if (!this.resolvedBy) {
        const user = this.users.find(
          (user) => user.id === this.$store.getters.user.id,
        );
        this.resolvedBy = user;
      }
    },
    cancel() {
      this.$emit('cancelled');
      this.close();
    },
    confirm() {
      this.$emit('resolved', this.resolvedBy);
      this.close();
    },
    close() {
      this.resolvedBy = null;
      this.reload = !this.reload;
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
