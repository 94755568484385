<template>
  <v-dialog v-model="dialog" width="700" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="msaBlue ml-4"
        height="36"
        v-bind="attrs"
        v-on="on"
        :style="{
          color: !isAssignable ? 'grey !important' : 'white !important',
          'border-radius': '4px',
        }"
        :disabled="!isAssignable"
      >
        <div>
          <v-icon> mdi-file-send-outline </v-icon>
          ASSIGN
        </div>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col class="grow"> Assign Form </v-col>
          <v-col class="shrink">
            <v-btn icon dark @click="close()"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              dense
              outlined
              hide-details
              label="Search"
            />
          </v-col>
          <v-col>
            <v-card>
              <v-tabs
                v-model="tab"
                color="msaBlue"
                grow
                background-color="lightBg"
                slider-size="5"
              >
                <v-tab> All </v-tab>
                <v-tab> Supervisor </v-tab>
                <v-tab> Manager </v-tab>
                <v-tab> Safety </v-tab>
                <v-tab> User </v-tab>
              </v-tabs>
            </v-card>
            <v-card class="mt-4">
              <v-data-table
                :items="computedUsers"
                :headers="headers"
                :search="search"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.fullname }}</td>
                    <td>
                      <v-btn
                        small
                        class="msaBlue white--text"
                        @click="assignForm(item)"
                      >
                        select
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AssignFormDialog',
  props: {
    users: {
      type: Array,
    },
    isAssignable: {
      type: Boolean,
    },
  },
  computed: {
    computedUsers() {
      switch (this.tab) {
        case 1:
          return this.users.filter((u) => u.isSupervisor);
        case 2:
          return this.users.filter((u) => u.isManager);
        case 3:
          return this.users.filter((u) => u.isSafetyRep);
        case 4:
          return this.users.filter(
            (u) => !u.isSupervisor && !u.isManager && !u.isSafetyRep,
          );
        default:
          return this.users;
      }
    },
  },
  data() {
    return {
      dialog: false,
      search: '',
      headers: [
        {
          text: 'Name',
          value: 'fullname',
          class: 'lightGrey',
        },
        {
          text: '',
          filterable: false,
          sortable: false,
          class: 'lightGrey',
          width: '40px',
        },
      ],
      tab: 0,
      selectedSignatureType: null,
    };
  },
  methods: {
    assignForm(user) {
      this.$emit('onAssign', user);
      this.close();
    },
    close() {
      this.search = '';
      this.dialog = false;
    },
    show(tab) {
      this.tab = tab;
      this.dialog = true;
    },
  },
};
</script>

<style></style>
