<!-- modified from https://github.com/darrenfang/vuetify-datetime-picker -->
<template>
  <v-dialog v-model="display" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
        outlined
        dense
        hide-details
        :disabled="disabled"
        :label="label"
        :value="displayedDateTime"
        :clearable="clearable"
        v-on="on"
        readonly
        @click:clear="clear()"
      >
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="pa-0">
        <v-tabs
          fixed-tabs
          v-model="activeTab"
          active-class="msaBlue white--text"
        >
          <v-tab>
            <v-icon :dark="activeTab == 0">mdi-calendar</v-icon>
          </v-tab>
          <v-tab :disabled="dateSelected">
            <v-icon :dark="activeTab == 1">mdi-clock-outline</v-icon>
          </v-tab>
          <v-tab-item>
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              @input="showTimePicker"
              full-width
              no-title
              :style="{ 'border-radius': 0 }"
              :max="dateMax"
              :min="dateMin"
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item>
            <v-time-picker
              ref="timer"
              v-model="time"
              v-bind="timePickerProps"
              full-width
              no-title
              format="24hr"
              :style="{ 'border-radius': 0 }"
              :max="timeMax"
              :min="timeMin"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="clearHandler()">
          {{ clearText }}
        </v-btn>
        <v-btn
          class="msaBlue white--text"
          @click="okHandler()"
          :disabled="!isSelectedDateTimeValid"
        >
          {{ okText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import * as constants from '@/constants.js';

const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00';
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = 'cancel';
const DEFAULT_OK_TEXT = 'OK';
const DEFAULT_MAX_DATE = constants.DATE_SELECTOR_RANGE.MAX;
const DEFAULT_MIN_DATE = constants.DATE_SELECTOR_RANGE.MIN;

export default {
  name: 'DateTimePicker',
  model: {
    prop: 'datetime',
    event: 'input',
  },
  watch: {
    datetime() {
      this.init();
    },
  },
  props: {
    datetime: {
      type: [Date, String],
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    max: {
      type: [Date, String],
      default: undefined,
    },
    min: {
      type: [Date, String],
      default: undefined,
    },
  },
  computed: {
    dateTimeValue() {
      return this.isSelectedDateTimeValid ? this.date + ' ' + this.time : '';
    },
    dateSelected() {
      return !this.date;
    },
    isSelectedDateTimeValid() {
      return DateTime.fromFormat(
        this.date + ' ' + this.time,
        DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT,
      ).isValid;
    },
    displayedDateTime() {
      return this.isSelectedDateTimeValid
        ? this.$options.filters.filterAsLocalDate(
            this.dateTimeValue,
            'LLL dd, yyyy',
          ) +
            ' at ' +
            this.$options.filters.filterAsLocalTime(
              this.dateTimeValue,
              'hh:mm a',
            )
        : '';
    },
    dateMax() {
      if (this.max == undefined) {
        return DEFAULT_MAX_DATE;
      }
      const dt = new Date(this.max).toISOString();
      const localDatetime = DateTime.fromISO(dt).toLocal();

      if (!localDatetime.isValid) {
        return DEFAULT_MAX_DATE;
      }

      return localDatetime.toFormat(DEFAULT_DATE_FORMAT);
    },
    dateMin() {
      if (this.min == undefined) {
        return DEFAULT_MIN_DATE;
      }
      const dt = new Date(this.min).toISOString();
      const localDatetime = DateTime.fromISO(dt).toLocal();

      if (!localDatetime.isValid) {
        return DEFAULT_MIN_DATE;
      }

      return localDatetime.toFormat(DEFAULT_DATE_FORMAT);
    },
    timeMax() {
      if (this.max == undefined) {
        return undefined;
      }
      let dt = new Date(this.max).toISOString();
      const localMaxDatetime = DateTime.fromISO(dt).toLocal();

      if (!localMaxDatetime.isValid) {
        return undefined;
      }

      if (this.date != '' && localMaxDatetime.isValid) {
        dt = DateTime.fromFormat(this.date, DEFAULT_DATE_FORMAT);
        if (localMaxDatetime.diff(dt, 'days').days >= 1) {
          return undefined;
        }
      }

      return localMaxDatetime.toFormat(DEFAULT_TIME_FORMAT);
    },
    timeMin() {
      if (this.min == undefined) {
        return undefined;
      }
      let dt = new Date(this.min).toISOString();
      const localMinDatetime = DateTime.fromISO(dt).toLocal();

      if (!localMinDatetime.isValid) {
        return undefined;
      }

      if (this.date != '' && localMinDatetime.isValid) {
        dt = DateTime.fromFormat(this.date, DEFAULT_DATE_FORMAT);
        if (localMinDatetime.diff(dt, 'days').days < 0) {
          return undefined;
        }
      }

      return localMinDatetime.toFormat(DEFAULT_TIME_FORMAT);
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
    };
  },
  methods: {
    init() {
      if (!this.datetime) {
        return;
      }
      const dt = new Date(this.datetime).toISOString();
      const localDatetime = DateTime.fromISO(dt).toLocal();

      if (!localDatetime.isValid) {
        return;
      }

      this.date = localDatetime.toFormat(DEFAULT_DATE_FORMAT);
      this.time = localDatetime.toFormat(DEFAULT_TIME_FORMAT);
    },
    okHandler() {
      this.resetPicker();
      this.$emit('input', this.dateTimeValue);
    },
    clearHandler() {
      this.resetPicker();
      this.init();
      this.$emit('input', this.datetime);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
    clear() {
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.okHandler();
    },
  },

  mounted() {
    this.init();
  },
};
</script>
