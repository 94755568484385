<template>
  <v-row v-if="hasSignature" dense>
    <v-col class="grow">
      <v-row dense>
        <v-col>
          {{ signatureTypeText }}
          {{
            signature.contactGuid == ''
              ? signature.signedByName
              : signature.contactFullname
          }}
          on
          {{ signature.createdOnMobileAt | filterAsLocalDate('LLL dd, yyyy') }}
          at
          {{ signature.createdOnMobileAt | filterAsLocalTime('hh:mm a') }}
        </v-col>
        <v-col cols="12" v-if="signature.contactCompanyName != ''">
          Company: {{ signature.contactCompanyName }}
        </v-col>
        <v-col cols="12"> Comment: {{ signature.comment }} </v-col>
      </v-row>
    </v-col>
    <v-col class="shrink" align="center">
      <v-img contain max-width="200px" max-height="200px" :src="imageSrc">
      </v-img>
    </v-col>
    <v-col class="shrink">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-blur
            small
            class="msaBlue white--text"
            v-on="on"
            :disabled="disableMapBtn"
            @click="$emit('viewOnMap')"
          >
            <v-icon> mdi-map-outline </v-icon>
          </v-btn>
        </template>
        <span>View on map</span>
      </v-tooltip>
    </v-col>
  </v-row>
  <v-row v-else align="center">
    <v-col cols="auto" class="font-weight-bold mr-auto">
      {{ signatureTypeText }}
    </v-col>
    <v-col cols="auto" v-if="isEditable">
      <v-btn
        v-if="
          areAllRequiredFieldsFilled &&
          typeId != $constants.SIGNATURE_TYPES.CREATED_BY &&
          !isPreview &&
          isAssignable
        "
        class="msaBlue white--text font-weight-bold"
        v-blur
        @click="$emit('assign')"
      >
        Assign
      </v-btn>
      <v-btn
        v-if="areAllRequiredFieldsFilled && isEditable"
        class="ml-4 lightBg msaBlue--text font-weight-bold"
        depressed
        v-blur
        @click="$emit('addSignature')"
        :disabled="disabledByPermission"
      >
        Click to sign
      </v-btn>
      <v-btn
        v-if="!areAllRequiredFieldsFilled && isEditable"
        disabled
        class="lightGrey grey--text font-weight-bold text-lowercase"
      >
        <span class="text-uppercase">e</span>nsure all required sections are
        completed prior to signing.
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormSignature',
  props: {
    signature: {
      type: Object,
    },
    typeId: {
      type: Number,
    },
    isEditable: {
      type: Boolean,
    },
    areAllRequiredFieldsFilled: {
      type: Boolean,
    },
    isPreview: {
      type: Boolean,
    },
    isAssignable: {
      type: Boolean,
    },
    binaryType: {
      type: Number,
    },
  },
  computed: {
    hasSignature() {
      return (
        this.signature != undefined && JSON.stringify(this.signature) != '{}'
      );
    },
    signatureTypeText() {
      switch (this.typeId) {
        case 1:
          return 'Completed By';
        case 2:
          return 'Supervisor';
        case 3:
          return 'Safety Rep';
        case 4:
          return 'Additional';
        case 5:
          return 'Manager';
        default:
          return '';
      }
    },
    imageSrc() {
      if (this.isUnsentSignature) {
        return this.signature.src;
      } else {
        return this.getSignature();
      }
    },
    isUnsentSignature() {
      return !Number.isInteger(this.signature.id);
    },
    disableMapBtn() {
      return this.signature.latitude == '' || this.signature.longitude == '';
    },
    disabledByPermission() {
      if (
        !this.$store.getters.user.isManager &&
        this.typeId == this.$constants.SIGNATURE_TYPES.MANAGER
      ) {
        return true;
      }

      if (
        !this.$store.getters.user.isSupervisor &&
        this.typeId == this.$constants.SIGNATURE_TYPES.SUPERVISOR
      ) {
        return true;
      }

      if (
        !this.$store.getters.user.isSafetyRep &&
        this.typeId == this.$constants.SIGNATURE_TYPES.SAFETY_REP
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    getSignature() {
      const endpoint =
        this.binaryType == this.$constants.BINARY_TYPES.FORM_SIGNATURE
          ? 'download-form-signature'
          : 'download-document-signature';
      return `${process.env.VUE_APP_BASE_URL}${endpoint}?format=image&id=${
        this.signature.id
      }&jwtToken=${this.$store.getters.jwtToken}
      &t=${new Date()}`;
    },
  },
  mounted() {
    if (this.hasSignature) {
      this.getSignature();
    }
  },
};
</script>

<style></style>
