<template>
  <v-container fluid>
    <v-row
      dense
      @click="$emit('showElementDetail')"
      :style="{ cursor: 'pointer' }"
      align="center"
    >
      <v-col class="font-weight-bold black--text shrink">
        <v-checkbox
          v-model="statusId"
          dense
          hide-details
          class="ma-0 pa-0"
          color="msaBlue"
          :false-value="$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE"
          :true-value="$constants.FORM_ELEMENT_STATUS.SATISFACTORY"
          @click.stop
          :disabled="!isEditable"
        ></v-checkbox>
      </v-col>
      <v-col class="grow black--text">
        <v-row dense>
          <v-col cols="12" class="font-weight-bold">
            {{ element.name }}
          </v-col>
          <v-col v-if="element.description.length > 0">
            {{ element.description }}
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="userResponseText"
              auto-grow
              dense
              counter
              maxlength="4000"
              outlined
              placeholder="Optional answer"
              rows="1"
              @click.stop
              :readonly="!isEditable"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-badge inline :value="imageCount > 0" :content="imageCount">
          <v-icon :color="imageCount > 0 ? 'msaBlue' : 'grey'">
            mdi-image-outline
          </v-icon>
        </v-badge>
        <v-badge inline :value="noteCount > 0" :content="noteCount">
          <v-icon :color="noteCount > 0 ? 'msaBlue' : 'grey'" class="ml-4">
            mdi-sticker-text-outline
          </v-icon>
        </v-badge>
        <v-badge inline :content="attachmentCount" :value="attachmentCount > 0">
          <v-icon :color="attachmentCount > 0 ? 'orange' : 'grey'" class="ml-4">
            mdi-paperclip
          </v-icon>
        </v-badge>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CheckboxElement',
  props: {
    element: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
    },
  },
  computed: {
    imageCount() {
      return this.element.images.length;
    },
    attachmentCount() {
      return this.element.attachments.length;
    },
    noteCount() {
      return this.element.notes.length;
    },
    statusId: {
      get() {
        return this.element.statusId;
      },
      set(value) {
        this.$emit('updateElement', {
          key: 'statusId',
          value,
        });
      },
    },
    userResponseText: {
      get() {
        return this.element.userResponseText;
      },
      set(value) {
        this.$emit('updateElement', {
          key: 'userResponseText',
          value,
        });
      },
    },
  },
};
</script>

<style></style>
