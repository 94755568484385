<template>
  <v-card>
    <v-card-title class="msaBlue white--text">
      {{ modalTitle }}
    </v-card-title>
    <v-card-text class="mt-4">
      <v-form ref="form">
        <v-row align="center">
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="edited.firstName"
              dense
              counter
              maxlength="100"
              outlined
              label="First Name"
              :rules="required"
              data-testid="firstName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="edited.lastName"
              dense
              counter
              maxlength="100"
              outlined
              label="Last Name"
              :rules="required"
              data-testid="lastName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="edited.companyName"
              dense
              counter
              maxlength="250"
              outlined
              label="Company"
              :rules="required"
              data-testid="companyName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="edited.jobTitle"
              dense
              counter
              maxlength="250"
              outlined
              label="Job Title"
              data-testid="jobTitle"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="edited.email"
              dense
              counter
              maxlength="250"
              outlined
              label="Email"
              :rules="rules.email"
              @keydown.space.prevent
              data-testid="email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="edited.phone"
              dense
              counter
              maxlength="15"
              outlined
              label="Phone Number"
              @keydown.space.prevent
              :rules="rules.phone"
              data-testid="phone"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row align="center" v-if="!isNew">
        <v-col class="shrink"> Status </v-col>
        <v-col align="left">
          <v-btn-toggle v-model="edited.status" mandatory>
            <v-btn
              width="100px"
              :value="1"
              :class="
                (edited.status ? 'green white--text' : '') + ' text-capitalize'
              "
            >
              Active
            </v-btn>
            <v-btn
              width="100px"
              :value="0"
              :class="
                (edited.status ? '' : 'orange white--text') + ' text-capitalize'
              "
            >
              Inactive
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="edited.emergencyName"
            dense
            counter
            maxlength="120"
            outlined
            label="Emergency Contact"
            @change="
              // trigger validation
              $refs.emergencyPhone.focus();
              $refs.emergencyPhone.blur();
            "
            data-testid="emergencyContact"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="edited.emergencyPhone"
            dense
            counter
            ref="emergencyPhone"
            maxlength="15"
            outlined
            label="Emergency Phone Number"
            :disabled="edited.emergencyName.trim().length == 0"
            @keydown.space.prevent
            :rules="
              edited.emergencyName.trim().length == 0
                ? []
                : rules.emergencyPhone
            "
            data-testid="emergencyPhone"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn
            text
            color="red"
            v-if="!isContactDetailsPage"
            @click="$emit('close')"
          >
            cancel
          </v-btn>
          <v-btn
            class="msaBlue white--text"
            :disabled="disableSave"
            @click="saveDetails()"
            data-testid="saveContact"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ContactDetailsPage',
  props: {
    id: {
      type: Number,
      default: -1,
    },
  },
  watch: {
    'edited.emergencyName'(val) {
      if (val.trim().length == 0) {
        this.edited.emergencyPhone = '';
      }
    },
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.edited) == JSON.stringify(this.contact) ||
        this.edited.firstName.trim().length == 0 ||
        this.edited.lastName.trim().length == 0 ||
        this.edited.companyName.trim().length == 0 ||
        (this.edited.emergencyName.trim().length > 0 &&
          !this.$refs.emergencyPhone.validate())
      );
    },
    isNew() {
      return this.id == -1;
    },
    isContactDetailsPage() {
      return this.$route.name == 'ContactDetails';
    },
    modalTitle() {
      return this.isContactDetailsPage ? 'General Information' : 'Add Contact';
    },
  },
  data() {
    const defaultProps = {
      id: -1,
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phone: '',
      jobTitle: '',
      emergencyPhone: '',
      emergencyName: '',
      status: 1,
    };
    return {
      contact: JSON.parse(JSON.stringify(defaultProps)),
      edited: JSON.parse(JSON.stringify(defaultProps)),
      required: [(v) => !!v || 'This field is required'],
      rules: {
        email: [(v) => this.$helpers.validateEmail(v, false)],
        phone: [(v) => this.$helpers.validatePhone(v)],
        emergencyPhone: [(v) => this.$helpers.validatePhone(v, true)],
      },
    };
  },
  methods: {
    getDetails() {
      if (!this.isNew) {
        const params = {
          loaderText: 'loading...',
          id: this.id,
        };
        const url = 'get-contact-details?format=json';
        this.$axios.post(url, params).then((response) => {
          this.contact = response.data;
          if (this.isContactDetailsPage) {
            this.$store.commit('updateSelectedContact', this.contact);
          }
          this.edited = JSON.parse(JSON.stringify(this.contact));
        });
      }
    },
    saveDetails() {
      this.edited.fullname = this.edited.firstName + ' ' + this.edited.lastName;
      const edited = { ...this.edited };
      edited.statusChanged = edited.status != this.contact.status;
      const params = {
        loaderText: 'loading...',
        contact: edited,
        snackText: 'Contact saved successfully.',
      };
      const url = this.isNew
        ? 'add-contact?format=json'
        : 'update-contact?format=json';
      this.$axios.post(url, params).then((response) => {
        if (this.isContactDetailsPage) {
          if (this.isNew) {
            this.$store.commit('updateSelectedContact', {
              ...edited,
              id: response.data.id,
              name: edited.fullname,
            });
            this.$router.replace({
              name: 'ContactDetails',
              params: { id: response.data.id },
            });
          } else {
            this.contact = { ...this.edited };
            this.$store.commit('updateSelectedContact', { ...this.edited });
          }
        } else {
          this.$emit('onSave', {
            id: response.data.id,
            fullname: edited.fullname,
            guid: response.data.guid,
            companyName: response.data.companyName,
          });
          this.$emit('close');
          this.$emit('getContacts');
        }
      });
    },
  },
  mounted() {
    this.getDetails();
    this.$nextTick(() => {
      this.$refs.form.validate();
    });
  },
};
</script>

<style></style>
